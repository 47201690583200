body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100vh;
    width: 100%;
    background-color: var(--dark-blue);
    scroll-behavior: smooth;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
    width: 100%;
    height: 100%;
}

.App {
    width: 100%;
    height: 100%;
}

:root {
    --red: #D9043D;
    --white: #D5E5F2;
    --dark-blue: #012941;
    --blue: #398CBF;
    --light-blue: #45A9BF;
}

.nav-bar {
    box-sizing: border-box;
    width: 100%;
    top: 0px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    padding: 1%;
    padding-right: 2%;
    background-color: transparent;
}

.right-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 30%;
}

.nav-link {
    color: white;
    text-decoration: none;
    transition: transform .2s ease-in-out;
}

.nav-link:hover {
    transform: scale(1.1);
}

.main {
    width: 100%;
    margin: 5% 0 0 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.main-message {
    top: 0px;
    margin: 0px;
    text-align: center;
    color: white;
}

.portfolio-boxes {
    display: flex;
    width: 100%;
    flex: 1 1 auto;
    height: 100%;
    color: white;
    flex-direction: column;
    text-align: center;
}

.boxes-container {
    max-width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
}

.portfolio-box {
    margin: 5% 0 5% 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 280px;
    height: 400px;
    border-radius: 5%;
    background-color: white;
}

.img-preview {
    height: 230px;
    margin: 5%;
    object-fit: contain;
    border-radius: 1%;
}

.project-title {
    margin: 0% 5% 0% 5%;
    text-align: left;
    color: black;
}

.see-project {
    margin-left: 5%;
    height: 35px;
    width: 100px;
    background-color: var(--red);
    border: none;
    border-radius: 5%;
    color: white;
    cursor: pointer;
    transition: transform .1s ease-in-out;
}
.see-project:disabled{
    opacity: 0.6;
    filter: brigthness(0.5);
    pointer-events: none;
}
.see-project:hover {
    transform: scale(1.1);
}


/* Color Theme Swatches in Hex */

.PHOTO-|-Chile-Travel-1-hex {
    color: #D9043D;
}

.PHOTO-|-Chile-Travel-2-hex {
    color: #D5E5F2;
}

.PHOTO-|-Chile-Travel-3-hex {
    color: #012840;
}

.PHOTO-|-Chile-Travel-4-hex {
    color: #398CBF;
}

.PHOTO-|-Chile-Travel-5-hex {
    color: #45A9BF;
}


/* Color Theme Swatches in RGBA */

.PHOTO-|-Chile-Travel-1-rgba {
    color: rgba(216, 4, 60, 1);
}

.PHOTO-|-Chile-Travel-2-rgba {
    color: rgba(213, 228, 242, 1);
}

.PHOTO-|-Chile-Travel-3-rgba {
    color: rgba(1, 39, 63, 1);
}

.PHOTO-|-Chile-Travel-4-rgba {
    color: rgba(57, 139, 191, 1);
}

.PHOTO-|-Chile-Travel-5-rgba {
    color: rgba(68, 168, 191, 1);
}


/* Color Theme Swatches in HSLA */

.PHOTO-|-Chile-Travel-1-hsla {
    color: hsla(343, 96, 43, 1);
}

.PHOTO-|-Chile-Travel-2-hsla {
    color: hsla(208, 53, 89, 1);
}

.PHOTO-|-Chile-Travel-3-hsla {
    color: hsla(202, 96, 12, 1);
}

.PHOTO-|-Chile-Travel-4-hsla {
    color: hsla(202, 53, 48, 1);
}

.PHOTO-|-Chile-Travel-5-hsla {
    color: hsla(191, 48, 51, 1);
}