:root {
    --red: #D9043D;
    --white: #D5E5F2;
    --dark-blue: #012941;
    --blue: #398CBF;
    --light-blue: #45A9BF;
}


/* portfolio elements styles */

.portfolio {
    padding-bottom: 5%;
}

.pfo-title {
    color: white;
    text-align: center;
}

.pfo-element {
    height: 400px;
    width: 80%;
    display: flex;
    flex-direction: row;
    background-color: white;
    margin: 5% 10% 0% 10%;
    transition: transform .2s ease-out;
    border-radius: 15px;
    box-sizing: border-box;
    padding-left: 15px;
}


/* .pfo-element:hover {
    transform: scale(1.01);
} */

.txt-container {
    width: 80%;
    margin: 3% 10% 17% 0%;
    height: 80%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
}

.pfo-btns {
    display: flex;
    flex-direction: row;
}

.pfo-el-btn {
    /* margin-left: 5%; */
    margin-left: 10%;
    height: 35px;
    width: 150px;
    background-color: var(--red);
    border: none;
    border-radius: 3px;
    color: white;
    cursor: pointer;
    transition: transform .1s ease-in-out;
}

.pfo-el-btn:hover {
    transform: scale(1.05);
}

.pfo-el-btn-2 {
    /* margin-left: 5%; */
    height: 35px;
    width: 150px;
    background-color: var(--blue);
    border: none;
    border-radius: 3px;
    color: white;
    cursor: pointer;
    transition: transform .1s ease-in-out;
}

.pfo-el-btn-2:hover {
    transform: scale(1.05);
}

.pfo-el-title {
    margin: 0px;
    color: var(--dark-blue);
}

.pfo-el-des {
    font-style: italic;
    margin: 0px;
    font-size: 20px;
}

.pfo-img {
    height: 100%;
    object-fit: cover;
    margin-right: 5%;
}


/* more information modal styles */


/* .pei-modal-container {
    left: 0px;
    width: 100%;
    height: 130%;
    top: 0px;
    position: absolute;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
} */

.portfolio-modal {
    filter: brightness(0.3);
    padding-bottom: 5%;
    /* opacity: 0.4; */
}

.pei-modal {
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 500px;
    width: 500px;
    background-color: white;
    border-radius: 15px;
    padding: 2%;
}

.quit {
    color: red;
    background-color: transparent;
    border: none;
    cursor: pointer;
}


/* palentar section styles */

.pltr-frame {
    background-color: white;
    width: 100%;
    height: 94%;
}

.pltr-frame-container {
    width: 100%;
    height: 94%;
}

.pltr-link {
    position: absolute;
    top: 96%;
    transform: translateX(-50%);
    left: 50%;
    margin: auto;
    text-align: center;
    color: white;
}