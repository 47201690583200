.wthr-frame-container{
    height: 110%;
    width: 100%;
}
.wthr-frame{
  height: 90%;
  width: 100%;
}
.new-page-link{
    width: 100%;
    color: white;
    text-align: center;
    margin: auto;
    cursor: pointer;
    text-decoration: underline;
    line-height: 100%;
    margin-top: 2%;
    vertical-align: middle;
    
}